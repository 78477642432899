import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NonMappedProductFamily } from 'src/modules/types/interfaces';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs/operators';
import { applyOnKeys, snakeToCamel } from '../../tools';
import { NonMappedProductFamilyStateModel } from 'src/modules/state-management/states/non-mapped-product-family.state';
import { queryBuilder } from '../../tools/queryBuilder';

@Injectable({
  providedIn: 'root'
})
export class NonMappedProductFamilyService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient,
    private snackBar: SnackBarService,
    private spinnerService: NgxSpinnerService
  ) { }

  getNonMappedProductFamily({ filters }: Pick<NonMappedProductFamilyStateModel, "filters">): Observable<NonMappedProductFamily[]> {
    this.spinnerService.show();
    const observable = this.http.get<NonMappedProductFamily[]>(`${this.apiUrl}/non_map_product_family?${queryBuilder(filters)}`, { observe: 'response' })
      .pipe(map((response) => {
        this.spinnerService.hide();
        if (response.status < 400) {
          return applyOnKeys(response.body as NonMappedProductFamily[], snakeToCamel);
        } else {
          this.snackBar.openSnackBar('Unexpected error occured', 'warning');
          throw new Error('Unexpected error occured');
        }
      }), catchError(error => {
        this.spinnerService.hide();
        this.snackBar.openSnackBar('Request failed!', 'error');
        console.error(error);
        return of(null);
      }));
    return observable;
  }

  getNonMappedProductFamilyPaginator({ filters }: Pick<NonMappedProductFamilyStateModel, "filters">): Observable<number> {
    this.spinnerService.show();
    const observable = this.http.get<number>(`${this.apiUrl}/non_map_product_family_paginator?${queryBuilder(filters)}`, { observe: 'response' })
      .pipe(map((response) => {
        this.spinnerService.hide();
        if (response.status < 400) {
          return applyOnKeys(response.body as number, snakeToCamel);
        } else {
          // this.snackBar.openSnackBar('Unexpected error occured', 'warning');
          throw new Error('Unexpected error occured');
        }
      }), catchError(error => {
        this.spinnerService.hide();
        // this.snackBar.openSnackBar('Request failed!', 'error');
        console.error(error);
        return of(0);
      }));
    return observable;
  }
}
