import { AuthState } from './states/auth';
import { AlertsState } from './states/alerts.state';
import { TransactionsState } from './states/transactions.state';
import { AlertsDetailsState } from './states/alert-information.state';
import { ListParamsState } from './states/list-params.state';
import { PaginatorState } from './states/paginator.state';
import { CurrentUserState } from './states/current-user.state';
import { UsersState } from './states/users.state';
import { UserPaginatorState } from './states/user-paginator.state';
import { UserParamsState } from './states/user-params.state';
import { NetworkCodeState } from './states/network-code.state';
import { NetworkCodePaginatorState } from './states/network-code-paginator.state';
import { NetworkCodeParamsState } from './states/network-code-params.state';
import { MicaProductState } from './states/mica-produc.state';
import { MicaProductPaginatorState } from './states/mica-product-paginator.state';
import { MicaProductParamsState } from './states/mica-product-params.state';
import { ExcludeMicaState } from './states/exclude-mica.state';
import { ExcludeMicaPaginatorState } from './states/exclude-mica-paginator.state';
import { ExcludeMicaParamsState } from './states/exclude-mica-params.state';
import { TransactionsArcyState } from './states/transactions-arcy.state';
import { TransactionsArcyParamsState } from './states/transactions-arcy-paginator.state';
import { BlackListedBinsState } from './states/blacklisted-bins.state';
import { BlacklistedNetworkParametersState } from './states/blacklisted-network-param.state';
import { NonMappedProductFamilyState } from './states/non-mapped-product-family.state';

export const globalState = [
  AuthState,
  AlertsState,
  TransactionsState,
  AlertsDetailsState,
  ListParamsState,
  PaginatorState,
  CurrentUserState,
  UsersState,
  UserPaginatorState,
  UserParamsState,
  NetworkCodeState,
  NetworkCodePaginatorState,
  NetworkCodeParamsState,
  MicaProductState,
  MicaProductPaginatorState,
  MicaProductParamsState,
  ExcludeMicaState,
  ExcludeMicaPaginatorState,
  ExcludeMicaParamsState,
  TransactionsArcyState,
  TransactionsArcyParamsState,
  BlackListedBinsState,
  BlacklistedNetworkParametersState,
  NonMappedProductFamilyState
];
