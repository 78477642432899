import { NonMappedProductFamily } from "src/modules/types/interfaces";
import { NonMappedProductFamilyStateModel } from "../states/non-mapped-product-family.state";

export class SetNonMappedProductFamily {
    static readonly type = '[nonMappedProductFamily] Get All';
    constructor(public payload: NonMappedProductFamily[]) { }
}

export class SetNonMappedProductFamilyPaginator {
    static readonly type = '[nonMappedProductFamily] Get Paginator';
    constructor(public payload: number) { }
}

export class SetNonMappedProductFamilyFilters {
    static readonly type = '[nonMappedProductFamily] Set Filters';
    constructor(public payload: Pick<NonMappedProductFamilyStateModel, "filters">) { }
}