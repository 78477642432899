export const ACCESS = {
    home: {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
            'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
        internal_routes: [{
            route: '/home',
            route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
                'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
            readonly: ['Reader', 'Affiliate_TF', 'Affiliate_TK',
                'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis']
        }]
    },
    kyt: {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
        internal_routes: [
            {
                route: '/kyt/card-alerts',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/kyt/badge-alerts',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/kyt/machine-learning',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/kyt/card-details',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
                readonly: ['Reader', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis']
            }, {
                route: '/kyt/badge-details',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
                readonly: ['Reader', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis']
            }, {
                route: '/kyt/machine-learning-alert-details',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }
        ]
    },
    'black-list': {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
        internal_routes: [
            {
                route: '/black-list/transaction-arcy',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/customer-factorisation',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: null,
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/parameters/network-parameters-blacklisted-bins',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/parameters/list-length',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/parameters/parametrage-modeles-de-saturation',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/ln-force',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/import-dans-cardpro',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/customers-by-networks',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/parameters/blacklist-parameters',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/black-list/blacklist-pipelines',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }
        ]
    }, administration: {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
        internal_routes: [{
            route: '/administration',
            route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
            readonly: ['Reader', 'Fraud Analyst', 'Analyst ML']
        }]
    }, auditability: {
        main_route_access: ['Administrator'],
        internal_routes: [{
            route: '/auditability',
            route_access: ['Administrator'],
            readonly: ['Reader', 'Fraud Analyst', 'Analyst ML']
        }]
    },
    configuration: {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
        internal_routes: [
            {
                route: '/configuration/rules-configuration',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: '/configuration/comments-configuration',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: '/configuration/dna-configuration',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: '/configuration/mapping-netowork-codes',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/configuration/mica-products',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/configuration/exclude-mica',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/configuration/providers-configuration',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: '/configuration/non-mapped-product-family',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            },
            {
                route: null,
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }
        ]
    },
    'alerts-follow-up': {
        main_route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
            'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
        internal_routes: [
            {
                route: '/alerts-follow-up/cards',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: '/alerts-follow-up/badges',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
                    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'],
                readonly: ['Reader']
            }, {
                route: '/alerts-follow-up/client-precision',
                route_access: ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
                readonly: ['Reader']
            }
        ]
    },
};
