import { NonMappedProductFamily } from "src/modules/types/interfaces";
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from "@angular/core";
import { SetNonMappedProductFamily, SetNonMappedProductFamilyFilters, SetNonMappedProductFamilyPaginator } from "../actions/non-mapped-product-family.actions";

export interface NonMappedProductFamilyStateModel {
    nonMappedProductFamily: NonMappedProductFamily[];
    totalRecords: number;
    filters: {
        offset: number;
        limit: number;
        startDate: string;
        endDate: string;
    }
}

@State<NonMappedProductFamilyStateModel>({
    name: 'nonMappedProductFamily',
    defaults: {
        nonMappedProductFamily: [], totalRecords: 0,
        filters: { offset: 0, limit: 10, startDate: "", endDate: "" }
    }
})

@Injectable({
    providedIn: 'root'
})

export class NonMappedProductFamilyState {

    @Action(SetNonMappedProductFamily)
    setNonMappedProductFamily(ctx: StateContext<NonMappedProductFamilyStateModel>, action: SetNonMappedProductFamily) {
        const state = ctx.getState();
        ctx.setState({ ...state, nonMappedProductFamily: action.payload, totalRecords: state.totalRecords });
    }

    @Action(SetNonMappedProductFamilyPaginator)
    setNonMappedProductFamilyPaginator(ctx: StateContext<NonMappedProductFamilyStateModel>, action: SetNonMappedProductFamilyPaginator) {
        const state = ctx.getState();
        ctx.setState({ ...state, nonMappedProductFamily: state.nonMappedProductFamily, totalRecords: action.payload });
    }

    @Action(SetNonMappedProductFamilyFilters)
    SetNonMappedProductFamilyFilters(ctx: StateContext<NonMappedProductFamilyStateModel>, action: SetNonMappedProductFamilyFilters) {
        const state = ctx.getState();
        ctx.setState({ ...state, filters: action.payload.filters });
    }
}